.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f0f0f0;
}

.nav-left,
.nav-right {
  flex: 1;
}

.nav-title {
  flex: 2;
  text-align: center;
  font-weight: bold;
}

.nav-right {
  display: flex;
  justify-content: flex-end;
}